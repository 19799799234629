import { screens } from "../utils/links";

export interface ListInformation {
  title: string;
  target: string;
}

export const gameSelectionOptions: ListInformation[] = [
  createMenuOption("Tap That", screens.tapThat.main),
  // createMenuOption("Test 1 2 3", screens.main),
];

function createMenuOption(title: string, target: string): ListInformation {
  return { title, target };
}
