import { create } from "domain";

interface ScoreEntry {
  userName?: string;
  score: number;
  time: Date;
}

const localStorageName = "TapThatScore";

export function setTapThatScores(value: ScoreEntry[]): void {
  localStorage.setItem(localStorageName, JSON.stringify(value));
}

export function getTapThatScores(sort: boolean = false): ScoreEntry[] {
  const value = localStorage.getItem(localStorageName) || "[]";
  const scores = JSON.parse(value);
  if (sort) {
    scores.sort(({ score: a = 0 }, { score: b = 0 }) => b - a);
  }
  return scores;
}

export function addNewTapThatScore(score: number): void {
  const scores = getTapThatScores();
  scores.push(createNewScore(score));
  setTapThatScores(scores);
}

export function createNewScore(
  score: number,
  time: Date = new Date(),
  userName: string = ""
): ScoreEntry {
  return {
    userName,
    score,
    time,
  };
}
