import React from "react";
import { Link } from "react-router-dom";
import RainbowButton from "../common/rainbowButton";
import { gameSelectionOptions, ListInformation } from "./gameOptions";
import HeaderLink from "../common/headerLink";

export default function GameSelection() {
  return (
    <div className="game-selection">
      <HeaderLink />
      <section>
        <h2>Pick your game</h2>
        <ol>
          {gameSelectionOptions.map(({ title, target }: ListInformation) => (
            <li key={target}>
              <Link className="rainbow-text" to={target}>
                <RainbowButton>{title}</RainbowButton>
              </Link>
            </li>
          ))}
        </ol>
      </section>
    </div>
  );
}
