import { Link } from "react-router-dom";
import { screens } from "../utils/links";

export default function HeaderLink() {
  return (
    <Link to={screens.main}>
      <h1 className="rainbow-text">Games To Poop With</h1>
    </Link>
  );
}
