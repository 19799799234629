import React from "react";
import { Link, useNavigate } from "react-router-dom";
import RainbowButton from "../common/rainbowButton";
import { screens } from "../utils/links";
import { gameplayDurationInSeconds } from "./gamePlay/gamePlay";
import HeaderLink from "../common/headerLink";

export default function TapThatInstructions() {
  const navigate = useNavigate();
  return (
    <div>
      <HeaderLink />
      <section className="instructions">
        <h2>
          Instructions for <span className="rainbow-text"> Tap That</span>
        </h2>
        <ul>
          <li>Tap the screen as fast as you can</li>
          <li>
            Keep Tapping till the game is over (only {gameplayDurationInSeconds}{" "}
            seconds)
          </li>
          <li>Try harder next time </li>
        </ul>
        <p className="cta-container">
          <Link className="cta" to={screens.tapThat.simpleGame}>
            <RainbowButton>Start Tapping</RainbowButton>
          </Link>
          <button
            className="no-button fake-link cta"
            onClick={() => {
              navigate(-1);
            }}
          >
            Go Back
          </button>
        </p>
      </section>
    </div>
  );
}
