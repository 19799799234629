import React from "react";
import "./mainMenu.css";
import { Link } from "react-router-dom";
import { screens } from "../utils/links";
import RainbowButton from "../common/rainbowButton";

export default function MainApp() {
  return (
    <div className="bigger-text">
      <h1 className="rainbow-text">Games To Poop With</h1>
      <h2>
        So... you're pooping eh?
        <br />
        <Link to={screens.gameSelection}>
          <RainbowButton>Lets get started</RainbowButton>
        </Link>
      </h2>
    </div>
  );
}
