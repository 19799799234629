export const screens = {
  main: "/",
  gameSelection: "/selection",
  tapThat: {
    main: "/TapThat",
    simpleGame: "/TapThat/play",
    instructions: "/TapThat/howTo",
    highScore: "/TapThat/highScore",
  },
};
