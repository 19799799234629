import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainApp from "./mainMenu";
import GameSelection from "./mainMenu/gameSelection";
import { screens } from "./utils/links";
import TapThat from "./tapThat";
import TapThatSimpleGame from "./tapThat/simpleGame";
import TapThatHighScore from "./tapThat/highScore";
import TapThatInstructions from "./tapThat/instructions";
import { AwsRum, AwsRumConfig } from "aws-rum-web";

let awsRum: AwsRum;
try {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn:
      "arn:aws:iam::350916962107:role/RUM-Monitor-us-east-1-350916962107-4028291329961-Unauth",
    identityPoolId: "us-east-1:32ea68b6-3b85-41ac-a656-31e5ff0289a4",
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    telemetries: ["performance", "errors", "http"],
    allowCookies: true,
    enableXRay: false,
  };

  const APPLICATION_ID: string = "5e224a03-3741-42e0-951c-ada7481f766a";
  const APPLICATION_VERSION: string = "1.0.0";
  const APPLICATION_REGION: string = "us-east-1";

  awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

const router = createBrowserRouter([
  {
    path: screens.main,
    element: <MainApp />,
  },
  {
    path: screens.gameSelection,
    element: <GameSelection />,
  },
  {
    path: screens.tapThat.main,
    element: <TapThat />,
  },
  {
    path: screens.tapThat.simpleGame,
    element: <TapThatSimpleGame />,
  },
  {
    path: screens.tapThat.instructions,
    element: <TapThatInstructions />,
  },
  {
    path: screens.tapThat.highScore,
    element: <TapThatHighScore />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
