import getRandom from "../../utils/randomNumber";
import { text } from "./inspirationalText";
import createParticle from "./particle";

export const gameplayDurationInSeconds = 15;

interface TapThatCallbacks {
  updateScoreHandler: (totalScore: number) => void;
  gameOverHandler: () => void;
  gameStartHandler: () => void;
}

// @ts-ignore
let boundClickHandler = null;

export default class TapThat {
  rootContainer;
  updateScoreCB;
  gameOverCB;
  gameStartCB;
  durationInSeconds = gameplayDurationInSeconds;
  title = "Tap That";
  titleClass = "tap-that";
  count = 0;
  playing = false;
  inspirationalText = [...text];
  usedInspirationalText: string[] = [];

  constructor(
    element: HTMLElement,
    { updateScoreHandler, gameOverHandler, gameStartHandler }: TapThatCallbacks
  ) {
    this.rootContainer = element;
    this.rootContainer.classList.add(this.titleClass);
    this.updateScoreCB = updateScoreHandler;
    this.gameOverCB = gameOverHandler;
    this.gameStartCB = gameStartHandler;
  }

  beginGame() {
    this.count = 0;
    this.updateScore();
    //@ts-ignore
    if (boundClickHandler) {
      this.rootContainer.removeEventListener(
        "click",
        //@ts-ignore
        boundClickHandler
      );
    }
    this.rootContainer.classList.add("full-screen");

    boundClickHandler = this.handleScreenClick.bind(this);
    //@ts-ignore
    this.rootContainer.addEventListener("click", boundClickHandler);
    this.countDownTimer(3);
  }

  countDownTimer(number: number) {
    const countdownFrequencyInMS = 1000;
    if (number > 0) {
      this.showText(String(number), countdownFrequencyInMS);
      setTimeout(() => {
        this.countDownTimer(number - 1);
      }, countdownFrequencyInMS + 100);
    } else {
      this.showText("Go!", countdownFrequencyInMS);
      this.startPlaying();
    }
  }

  updateScore() {
    this.updateScoreCB(this.count * 13);
  }

  startPlaying() {
    this.playing = true;
    this.gameStartCB();
    setTimeout(this.stopPlaying.bind(this), this.durationInSeconds * 1000);
  }

  stopPlaying() {
    this.playing = false;
    this.gameOverCB();
  }

  showInspirationalText() {
    let text = "Still going eh?";
    if (this.inspirationalText.length > 0) {
      text = this.inspirationalText.splice(
        getRandom(0, this.inspirationalText.length),
        1
      )[0];
      this.usedInspirationalText.push(text);
    } else {
      this.inspirationalText = [...this.usedInspirationalText];
      this.usedInspirationalText = [];
    }

    this.showText(text, Math.min(3000, Math.max(500, text.length * 100)));
  }

  showText(text: string, duration: number = 500) {
    const div = document.createElement("div");
    div.appendChild(document.createTextNode(text));
    div.classList.add("popup-word");
    this.rootContainer.appendChild(div);

    const animation = div.animate(
      [
        {
          // Set the origin position of the particle
          // We offset the particle with half its size to center it around the mouse
          transform: `scale(1)`,
          opacity: 1,
        },
        {
          // We define the final coordinates as the second keyframe
          transform: `scale(2)`,
          opacity: 0.5,
        },
      ],
      {
        // Set a random duration from 500 to 1500ms
        duration,
        // easing: "ease-in-out(0, .9, .57, 1)",
      }
    );
    animation.onfinish = () => {
      div.remove();
    };
  }

  handleScreenClick(e: PointerEvent) {
    console.log("in screen click", this.playing);
    if (this.playing) {
      this.count++;
      if (this.count % 30 === 0) {
        this.showInspirationalText();
      }
      this.updateScore();

      for (let i = 0; i < 30; i++) {
        console.log("ff");
        createParticle(e.clientX, e.clientY);
      }
    }
  }
}
