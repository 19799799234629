export const text = [
  "Wow, look at you go",
  "Really tapping huh!",
  "So... this it?",
  "🚽🚽🚽",
  "💩💩💩",
  "How's that turd?",
  "Shouldn't you be at work?",
  "How long you been poopin?",
  "Don't forget to wipe",

  "Stop reading, keep Tapping!",
  "This a no wipe poop?",
  "Just how you like it nice and sloppy...",
  "Ever feel cold after a poop?",
];
