import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import RainbowButton from "../common/rainbowButton";
import { numberWithCommas } from "../utils/numberFormatter";
import TapThat, { gameplayDurationInSeconds } from "./gamePlay/gamePlay";
import { addNewTapThatScore } from "../utils/localStorage";
import Countdown from "../common/countdown";

let tt: TapThat | undefined;

export default function TapThatSimpleGame() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [totalScore, setTotalScore] = useState(0);
  const [isGameover, setIsGameover] = useState(false);
  const [hasGameStarted, setHasGameStarted] = useState(false);

  useEffect(() => {
    if (!tt) {
      // @ts-ignore
      tt = new TapThat(ref.current, {
        updateScoreHandler: setTotalScore,
        gameOverHandler: handleGameOver,
        gameStartHandler: handleGameStarted,
      });
      tt.beginGame();
    }
  });

  useEffect(() => {
    if (isGameover) {
      addNewTapThatScore(totalScore);
    }
  }, [isGameover, totalScore]);

  function handleGameOver(): void {
    setIsGameover(true);
    setHasGameStarted(false);
  }
  function handleGameStarted(): void {
    setHasGameStarted(true);
  }

  function handleRestart() {
    tt = undefined;
    setIsGameover(false);
    setHasGameStarted(false);
  }

  return (
    <div>
      <h1 className="rainbow-text">Games To Poop With</h1>
      {isGameover ? null : <div ref={ref} />}
      {isGameover ? (
        // the key is needed to prevent weird user selection
        <div key="gameover" className="game-over">
          <div>
            <h2>Game Over</h2>
            <div style={{ textAlign: "center" }}>
              <strong>Final Score:</strong> {numberWithCommas(totalScore)}
            </div>
          </div>
          <p>
            <button className="no-button" onClick={handleRestart}>
              <RainbowButton className="cta">Again?</RainbowButton>
            </button>
            <button
              className="no-button fake-link cta"
              onClick={() => {
                handleRestart();
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </p>
        </div>
      ) : null}
      {hasGameStarted ? (
        <div className="score-time-container">
          <div className="">
            Time left:{" "}
            <Countdown totalLength={gameplayDurationInSeconds} frequency={1} />
          </div>
          <div className="score">Score: {numberWithCommas(totalScore)}</div>
        </div>
      ) : null}
    </div>
  );
}
