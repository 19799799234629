import React from "react";
import "./common.css";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}
//@ts-ignore
export default function RainbowButton({ children, className }: Props) {
  return (
    <div className={`rainbow-button ${className}`}>
      <span></span>
      {children}
    </div>
  );
}
