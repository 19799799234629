import React from "react";
import "./tapThat.css";
import { Link, useNavigate } from "react-router-dom";
import { screens } from "../utils/links";
import RainbowButton from "../common/rainbowButton";
import HeaderLink from "../common/headerLink";

export default function TapThat() {
  return (
    <div>
      <HeaderLink />
      <section className="start-screen">
        <Link className="cta" to={screens.tapThat.simpleGame}>
          <RainbowButton>Play</RainbowButton>
        </Link>
        <Link className="cta" to={screens.tapThat.highScore}>
          <RainbowButton>High Scores</RainbowButton>
        </Link>
        <Link className="cta" to={screens.tapThat.instructions}>
          <RainbowButton>Instructions</RainbowButton>
        </Link>
      </section>
    </div>
  );
}
