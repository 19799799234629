import React, { useState } from "react";
import HeaderLink from "../common/headerLink";
import { getTapThatScores } from "../utils/localStorage";
import { numberWithCommas } from "../utils/numberFormatter";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { useNavigate } from "react-router";

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo("en-US");

export default function TapThatHighScore() {
  const [highScores] = useState(getTapThatScores(true));
  const navigate = useNavigate();

  return (
    <div className="high-score">
      <HeaderLink />
      <section>
        <h2>High Scores</h2>
        <ol>
          {highScores.slice(0, 5).map(({ score, time }, i) => (
            <li>
              <div>{i + 1}.</div>
              <div>{numberWithCommas(score)}</div>
              <div> {timeAgo.format(new Date(time))}</div>
            </li>
          ))}
        </ol>
        <button
          className="no-button fake-link cta"
          onClick={() => {
            navigate(-1);
          }}
        >
          Go Back
        </button>
      </section>
    </div>
  );
}
