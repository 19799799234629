import { useEffect, useState } from "react";

const useCountdown = (
  totalLengthInSeconds: number,
  frequencyInSeconds: number
) => {
  const [timeRemaining, setTimeRemaining] = useState(totalLengthInSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(timeRemaining - frequencyInSeconds);
    }, frequencyInSeconds * 1000);

    return () => clearInterval(interval);
  }, [timeRemaining]);

  return timeRemaining;
};

interface CountdownProps {
  totalLength: number;
  frequency: number;
}
export default function Countdown({ totalLength, frequency }: CountdownProps) {
  const timeRemaining = useCountdown(totalLength, frequency);
  return <span>{timeRemaining}</span>;
}
